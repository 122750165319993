import { NgModule } from '@angular/core';
import { CheckboxComponent } from './checkbox.component';
import { PseudoCheckboxComponent } from './pseudo-checkbox.component';

const directives = [CheckboxComponent, PseudoCheckboxComponent];

@NgModule({
  imports: directives,
  exports: directives,
})
export class FmntsCheckboxModule {}
