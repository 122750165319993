import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiFileResponse } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import type { TimeSpan } from '@fmnts/core/chronos';
import { map, type Observable } from 'rxjs';
import { ApiStatisticsPageAdapter } from '../adapter/statistics-page.adapter';
import { ApiTeamStatisticsAdapter } from '../adapter/team-statistics.adapter';
import { IApiStatisticsPage, IApiTeamStatistics } from '../api-model/api-model';
import { StatisticsPage } from '../model/statistics-page';
import { TeamStatistics } from '../model/team-statistics';

/**
 * API for interacting with the endpoint for petition statistics for teams.
 */
@Injectable({
  providedIn: 'root',
})
export class PetitionTeamStatisticsApi {
  private apiHelper = inject(ApiRequestHelper);
  private configService = inject(ApiConfigService);
  private http = inject(HttpClient);

  private static readonly rootUrl = '/v1/petition-team-stats';

  private readonly apiToTeamStatsAdapter = new ApiTeamStatisticsAdapter();
  private readonly apiToTeamStatsPageAdapter = new ApiStatisticsPageAdapter(
    this.apiToTeamStatsAdapter,
  );

  /**
   * Fetches the team statistic details for a petition
   *
   * @param id ID of the petition
   * @param dateRange
   */
  public get(id: string, dateRange: TimeSpan): Observable<TeamStatistics> {
    const url = this.configService.buildCockpitApiUrl([
      PetitionTeamStatisticsApi.rootUrl,
      id,
    ]);

    const params = {
      date_from: dateRange[0].format('YYYY-MM-DD'),
      date_to: dateRange[1].format('YYYY-MM-DD'),
    };

    return this.http
      .get<IApiTeamStatistics>(url, { params })
      .pipe(map((response) => this.apiToTeamStatsAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<StatisticsPage<TeamStatistics>> {
    const url = this.configService.buildCockpitApiUrl([
      PetitionTeamStatisticsApi.rootUrl,
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<IApiStatisticsPage<IApiTeamStatistics>>(url, { params })
      .pipe(map((response) => this.apiToTeamStatsPageAdapter.adapt(response)));
  }

  /**
   *
   * @param format Chosen download format (e.g. "csv", "xslx")
   * @param filters Filters used for download
   */
  public downloadStatisticsFile(
    format: string,
    filters: string | Record<string, any>,
  ): Observable<ApiFileResponse> {
    const url = this.configService.buildCockpitApiUrl([
      PetitionTeamStatisticsApi.rootUrl,
      format,
    ]);
    const params = this.apiHelper.makeParams(filters);

    return this.http.get<ApiFileResponse>(url, { params });
  }
}
