import { LegacyMetricsType, Translatable } from '@fmnts/api/shared';

export interface IStatisticsItem<T = unknown> {
  slug: string;
  title: Translatable<string>;
  order: number;
  value: T;
  type: LegacyMetricsType;
  /** Whether the item can be used for sorting. */
  isSortable?: boolean;
}

// TODO(arch-domain): move to shared/statistics or split up for cockpit/next
export class StatisticsItem<T = unknown> implements IStatisticsItem<T> {
  slug: string;
  order: number;
  value: T;
  type: LegacyMetricsType;
  title: Translatable<string>;
  isSortable: boolean;

  constructor(json: IStatisticsItem<T>) {
    this.slug = json.slug;
    this.order = json.order;
    this.value = json.value;
    this.type = json.type;
    this.title = json.title;
    this.isSortable = _coerceIsSortable(this.type);
  }
}

function _coerceIsSortable(type: LegacyMetricsType): boolean {
  switch (type) {
    case LegacyMetricsType.Average:
    case LegacyMetricsType.Metric:
    case LegacyMetricsType.Money:
    case LegacyMetricsType.Percentage:
      return true;
    default:
      return false;
  }
}
